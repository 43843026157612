//## For each of Bootstrap's buttons, define text, background and border color.

$body-text-color: #575962;
$white-color: #ffffff;
$grey-color: #f5f7fd;
$grey2-color: #f9fbfd;
$black-color: #191919;
$transparent-bg: transparent;
$dark-color: #0f1c2e;
$dark2-color: #12263c;
$primary-color: #1572e8;
$primary2-color: #0e60b1;
$secondary-color: #4c8fe3;
$secondary2-color: #3878c1;
$info-color: #4f8fd9;
$info2-color: #3a7cb5;
$success-color: #2e9c30;
$success2-color: #267f29;
$warning-color: #f1b52a;
$warning2-color: #e0a62b;
$danger-color: #d94347;
$danger2-color: #c23a44;
$green-color: #187c2c;
$green2-color: #146622;

// Font Weight
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-bold: 600;
$font-weight-extrabold: 700;
